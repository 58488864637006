* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 10px;
}

html,
body,
#root,
.sc-bdvvtL {
  // height: 100%;
  margin: 0;
}
.sc-bczRLJ {
  height: 100%;
  margin: 0;
}
.sc-bdvvtL,
.sc-bczRLJ {
  display: flex;
  flex-flow: column;
  // max-height: 100vh;
  overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span {
  font-family: "Encode Sans","Encode Sans SemiExpanded","Encode Sans Expanded","Encode Sans SemiCondensed","Encode Sans Condensed",sans-serif;
}

//header

// .header-container {
//   border-top: 4px solid #51a8e6;
// }

.header {
  width: 100%;
  // height: 6.9rem;
  max-width: 1280px;
  margin: 0 auto;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header img {
  margin: 1rem 0 1rem 5rem;
  max-width: 7rem;
}

/* container*/

.c-container {
  max-width: 1280px;
  margin: 0 auto;
}

@media only screen and (min-width: 1952px) {
  .top-texts {
    margin-left: 1.05rem !important;
  }
}
@media only screen and (min-width: 2005px) {
  .top-texts {
    margin-left: -3rem !important;
  }
}
@media only screen and (min-width: 2209px) {
  .top-texts {
    margin-left: -10rem !important;
  }
}
@media only screen and (min-width: 2374px) {
  .top-texts {
    margin-left: -15rem !important;
  }
}
@media only screen and (min-width: 2491px) {
  .top-texts {
    margin-left: -20rem !important;
  }
}
@media only screen and (max-width: 1280px) {
  .c-container,
  .header {
    max-width: 960px;
  }

  * {
    font-size: 8px;
  }
}
@media only screen and (min-width: 1280px) {
  .sc-bdvvtL {
    // overflow-y: scroll;
  }
  .sc-bczRLJ {
    min-height: 100vh;
    overflow-y: scroll;
  }
  .why-register-content .why-register-steps .why-register-text {
    font-size: 12px;
  }
  .promotion-card-content {
    padding: 10px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 500px) {
  .sc-bdvvtL {
    // min-height: 100vh;
    // overflow-y: scroll;
  }
  .sc-bczRLJ {
    min-height: 100vh;
    overflow-y: scroll;
  }
}
.card-content {
  gap: 0.5rem;
}
@media only screen and (max-width: 1024px) {
  .c-container,
  .header {
    max-width: 960px;
  }

  * {
    font-size: 5px;
  }
}

@media only screen and (max-width: 769px) {
  .c-container,
  .header {
    max-width: 700px;
  }

  * {
    font-size: 7px;
  }

  .right-container,
  .right-content {
    height: 100vh !important;
  }
}
@media only screen and (max-width: 769px) and (min-width: 580px) {
  .right-container,
  .right-content {
    height: auto !important;
  }
  .why-register-content .why-register-steps {
    margin-right: 2rem !important;
  }
  .why-register-steps .why-register-text {
    font-size: 1.5rem !important;
  }
}
/* margins */

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.4rem !important;
}

.mt-2 {
  margin-top: 0.6rem !important;
}

.mt-3 {
  margin-top: 0.8rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.ml-1 {
  margin-left: 0.4rem;
}

.ml-2 {
  margin-left: 0.6rem;
}

.ml-3 {
  margin-left: 0.8rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 0.4rem;
}

.mr-2 {
  margin-right: 0.6rem;
}

.mr-3 {
  margin-right: 0.8rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.4rem !important;
}

.mb-2 {
  margin-bottom: 0.6rem !important;
}

.mb-3 {
  margin-bottom: 0.8rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3.6rem !important;
}

/* width */

.w-100 {
  width: 100% !important;
}
.w-60 {
  width: 60% !important;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50% !important;
}

/*displays*/

.d-flex {
  display: flex;
}

.d-column {
  flex-direction: column;
}

.d-label {
  font-size: 1.8rem !important;
  color: #0096d7 !important;
}

/*margins*/

.mx-auto {
  margin: 0 auto;
}

/*texts*/
.text-center {
  text-align: center;
}

// .uppercase {
//   text-transform: uppercase;
// }

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 3.6rem;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 0.1em;
}

.btn-lg {
  padding: 2rem 1rem;
}

.btn-block {
  width: 100%;
}

/*inputs */

.input-group input,
.input-group select,
.input-group textarea {
  // background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 1.6rem;
  padding: 1.5rem;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 160%;
  color: #1c4167;

  &.input-error {
      border: 2px solid;
      border-color: #e63946;
      border-radius: 1.8rem;

      &--non-configurable {
          border: 2px solid #e63946 !important;
      }
  }
}

.textarea {
  flex: 1 1 100% !important;
}

.input-group select option {
  font-size: 1.8rem;
  color: #1c4167;
}

.input-label {
  position: relative;
}

.input-label .required {
  position: absolute;
  top: -4px;
}

.input-content {
  display: flex;
  align-items: center;
}

//alignment

.tcenter {
  text-align: center;
}

//line

.registration-content .horizontal-line {
  width: 100%;
  height: 0px;
  border: 1px solid #213e57;
}

.registration-content .vertical-line {
  width: 1px;
  background-color: #213e57;
}

.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: 0.8;
  z-index: 1000;
}

.error {
  color: #e63946;
  font-size: 16px;
  display: inline-block;
  text-align: left;

    &--non-configurable {
        color: #e63946 !important;
    }
}
.visible {
  display: none;
}
// .stepper-label-active .MuiStepLabel-iconContainer {
//   border: 1px solid #6e8ca5;
//   padding: 4px;
//   border-radius: 100%;
// }
.steps > span {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  font-size: 12px !important;
  padding: 0;
}
.css-8t49rw-MuiStepConnector-line {
  border-color: #bdbdbd;
  border-right-style: solid;
  border-right-width: 1px;
  // margin-right: 40px;
}
//modal
.modal-container {
  // background-color: #dbe6ed;
  position: absolute;
  top: 50%;
  left: 50%;
  // width: auto;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 2rem 1rem 2rem 2rem;
  margin: auto;
  // height: 258px;
  overflow: hidden;
  background-color: #fff;
  .modal-header a {
    top: 20px !important;
    right: 20px !important;
  }
}
#onetrust-banner-sdk {
  #onetrust-policy-text,
  #onetrust-accept-btn-handler,
  #onetrust-reject-all-handler,
  #onetrust-pc-btn-handler.cookie-setting-link {
    // font-size: 1.15em !important;
    font-size: 10px !important;
    letter-spacing: 0.03rem !important;
  }
  #onetrust-policy-text {
    padding-bottom: 25px !important;
  }
  .otFloatingRoundedCorner {
    max-width: 385px !important;
  }
  // #onetrust-pc-btn-handler {
  //   margin-top: 1rem !important;
  // }
}

font {
  font-size: inherit !important;
}

@media only screen and (min-width: 1000px) {
  #onetrust-banner-sdk {
    #onetrust-policy-text,
    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler,
    #onetrust-pc-btn-handler.cookie-setting-link {
      font-size: 12px !important;
    }
  }
  #onetrust-banner-sdk.otFloatingRoundedCorner {
    max-width: 400px !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
