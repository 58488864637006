* {
  font-size: 8px;
}

.header img {
  margin: 1rem 0 1rem 3.8rem;
}

.left-content p {
  width: 100% !important;
}

#root {
  height: min-content !important;
}

.top-texts {
  top: 0;
  left: 0;
  margin-top: 1rem;
  margin-left: 3.8rem;
}

.top-texts .top-label {
  font-size: 4rem;
  margin-top: 2rem;
}

.bottom-content {
  top: 0;
  left: 0;
  margin-top: 1.5rem;
  margin-left: 0rem;
  width: 93%;
  position: unset;
}

.registration-step {
  margin-bottom: 6.5rem;
}

.homepage-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.hmpg-top-texts-content {
  display: none;
}

.why-register-content {
  flex-direction: column;
  margin-bottom: 3rem;
}

.why-register-divider {
  height: 0px;
  width: 100%;
  transform: rotate(0);
  opacity: 0.3;
  border: 0.6px solid #ffffff;
  margin-left: 7rem;
}

.why-register-steps {
  display: flex;
}

.why-register-steps .why-register-step-count {
  margin-right: 1.8rem;
  font-size: 3rem;
  margin-top: 1rem;
}

.why-register-steps .why-register-text {
  font-size: 2rem;
  width: 100%;
}

.why-register-divider {
  margin: 2rem 0;
}

.container {
  margin: 0rem 3.8rem;
  display: flex;
  flex-direction: column;
}

.register-content {
  margin: -8rem 3.8rem;
}

.mobile-reverse {
  flex-direction: column-reverse;
}

.buttons {
  width: 100%;
  margin: 5rem auto;
}

/* check status page */

.input-content {
  flex-direction: column;
}

.input-content img {
  margin-left: auto;
  margin-top: 0.7rem;
}

.input-group input {
  // padding: 3.5rem;
}

.top-texts-content {
  margin-top: 4.8rem;
}

.top-texts-content,
.top-texts-content p {
  width: 100%;
}

.input-group input {
  width: 100%;
}

.button-group {
  width: 100%;
}

.line-content {
  width: 100%;
}

//registration page stepper
.registration-content {
  flex-direction: column;
}

.MuiStepLabel-horizontal {
  flex-direction: column;
}

.MuiStepLabel-label {
  text-align: center;
}

.product-content .promotion-content {
  margin: 0 16px;
}

.input-content .info-content {
  width: 100%;
  text-align: right;
}

.info-content .info-box {
  top: 5.5rem;
  text-align: left;
}

.info-content .info-box .info-vector {
  top: -0.4rem;
  right: 0.5rem;
  left: auto;
}
.modal-content,
.css-1yhl6yp {
  width: 90% !important;
}
.input-group .howto-link2 {
  font-size: 11px;
  width: 100% !important;
}
.modal-content .center-content .button-group {
  margin: 0 auto;
}
.css-181k718 {
  width: 100% !important;
  left: 0 !important;
}

.regions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.region {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select-country .countries .region > ul:first-child {
  margin-left: 20% !important;
}

.select-country .countries .region > ul {
  width: 100%;
  margin-left: 20%;
}
