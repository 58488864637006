// Encode Sans
@font-face {
  font-family: "Encode Sans Thin";
  src: url("../fonts/Encode-Sans/EncodeSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans ExtraLight";
  src: url("../fonts/Encode-Sans/EncodeSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Light";
  src: url("../fonts/Encode-Sans/EncodeSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Medium";
  src: url("../fonts/Encode-Sans/EncodeSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiBold";
  src: url("../fonts/Encode-Sans/EncodeSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Bold";
  src: url("../fonts/Encode-Sans/EncodeSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans ExtraBold";
  src: url("../fonts/Encode-Sans/EncodeSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Black";
  src: url("../fonts/Encode-Sans/EncodeSans-Black.ttf") format("truetype");
}

// Encode Sans Condensed
@font-face {
  font-family: "Encode Sans Condensed Thin";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed ExtraLight";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed Light";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed Regular";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed Medium";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed SemiBold";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed Bold";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed ExtraBold";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Condensed Black";
  src: url("../fonts/Encode-Sans/EncodeSans_Condensed-Black.ttf") format("truetype");
}

// Encode Sans SemiCondensed
@font-face {
  font-family: "Encode Sans SemiCondensed Thin";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed ExtraLight";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed Light";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed Regular";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed Medium";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed SemiBold";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed Bold";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed ExtraBold";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiCondensed Black";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiCondensed-Black.ttf") format("truetype");
}

// Encode Sans SemiExpanded
@font-face {
  font-family: "Encode Sans SemiExpanded Thin";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded ExtraLight";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded Light";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded Regular";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded Medium";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded SemiBold";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded Bold";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded ExtraBold";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans SemiExpanded Black";
  src: url("../fonts/Encode-Sans/EncodeSans_SemiExpanded-Black.ttf") format("truetype");
}


// Encode Sans Expanded
@font-face {
  font-family: "Encode Sans Expanded Thin";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded ExtraLight";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded Light";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded Regular";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded Medium";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded SemiBold";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded Bold";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded ExtraBold";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Encode Sans Expanded Black";
  src: url("../fonts/Encode-Sans/EncodeSans_Expanded-Black.ttf") format("truetype");
}

// Roboto
@font-face {
  font-family: "roboto";
  src: url("../fonts/roboto/Roboto-Bold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "roboto";
  src: url("../fonts/roboto/Roboto-Thin.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}
// Arctic
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/Gilroy-Bold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/Gilroy-Thin.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/Gilroy-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/Gilroy-SemiBold.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/Gilroy-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}

//Elektrabregenz

@font-face {
  font-family: "Poppins";
  font-style: normal;
  // font-weight: 400;
  src: url("../fonts/Poppins/poppins-v20-latin-regular.eot");
  src: local(""),
    url("../fonts/Poppins/poppins-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Poppins/poppins-v20-latin-regular.woff2") format("woff2"),
    url("../fonts/Poppins/poppins-v20-latin-regular.woff") format("woff"),
    url("../fonts/Poppins/poppins-v20-latin-regular.ttf") format("truetype"),
    url("../fonts/Poppins/poppins-v20-latin-regular.svg#Poppins") format("svg");
}

//grundig
@font-face {
  font-family: Basics;
  src: url(../fonts/grundig/grundig-basics-regular.woff2) format("woff2"),
    url(../fonts/grundig/grundig-basics-regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Basics;
  src: url(../fonts/grundig/grundig-basics-semibold.woff2) format("woff2"),
    url(../fonts/grundig/grundig-basics-semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Basics;
  src: url(../fonts/grundig/grundig-basics-regular-italic.woff2) format("woff2"),
    url(../fonts/grundig/grundig-basics-regular-italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Basics;
  src: url(../fonts/grundig/grundig-basics-semibold-italic.woff2) format("woff2"),
    url(../fonts/grundig/grundig-basics-semibold-italic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}
//blomberg
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/Helvetica-Bold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/helvetica-light-587ebe5a59211.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/helvetica-rounded-bold-5871d05ead8de.otf")
    format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/helvetica-compressed-5871d14b6903a.otf")
    format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/Helvetica.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
