@import url("../global.scss");

/* Home Page */

.homepage-content {
  display: grid;
  grid-template-columns: 49.8% 0.2% 49.8%;
  flex: 1 1 auto;
  overflow-y: hidden;
  // height:90vh
}
.register-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.left-content {
  display: flex;
  flex-direction: column;
  color: #fff;
}

.background-image {
  mix-blend-mode: normal;
  height: 100%;
  background-size: cover !important;
  display: flex;
}

.top-texts {
  // margin-left: 6.8rem;
  left: 0;
  margin: 0 6rem;
}

.label-constants {
  font-weight: normal !important;
  font-style: normal;
}

.top-texts .top-label {
  font-size: 6rem;
  line-height: 119.2%;
  width: 75%;
}

.top-texts .sub-top-label {
  font-size: 2.3rem;
  letter-spacing: 0.01em;
  margin-top: 2rem;
}

.bottom-content {
  margin-left: 2rem;
  width: 95%;
  margin-bottom: 15px;
  transform: scale(0.9);
}

.bottom-texts .bottom-label {
  font-size: 3.6rem;
}

.bottom-texts .sub-bottom-label {
  font-size: 1.8rem;
  letter-spacing: 0.01em;
  width: 70% !important;
  margin-top: 1.2rem;
}
.why-register {
  display: flex;
  justify-content: center;
  width: 90%;
}
.why-register-content {
  // padding: 4rem 5rem 3rem 4rem;
  padding: 3rem;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.28);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 2.4rem;
  margin: 3rem 1rem 0rem 0rem;

  .why-register-steps {
    line-height: 16px;
    letter-spacing: 0.1rem;
    font-weight: 400;
    margin-right: 3rem;

    .why-register-text {
      font-size: 12px;
      width: 115px;
    }
  }
}

.why-register-divider {
  opacity: 0.3;
  border: 1px solid #ffffff;
  transform: rotate(-90deg);
  height: 0;
  width: 11.7rem;
  margin: 5rem 0;
}

.why-register-steps .why-register-step-count {
  font-size: 2.3rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.why-register-steps .why-register-text {
  font-size: 1.6rem !important;
  width: 100% !important;
}

/* right panel */

.right-content {
  background-color: #0a2033;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 3rem 1.7rem;
  transform: scale(0.9);
  max-width: 100%;
}
.registration-form {
  margin: 0rem 1.7rem;
  transform: scale(0.8);
}
.register-content {
  transform: scale(0.78);
  margin: -4rem 2.7rem;
  min-width: 100%;
  margin-top: -8rem;
}
.hmg-top-text-label {
  font-size: 6.4rem;
}

.top-text-label {
  font-size: 3.6rem;
}

.top-text-sub-label {
  font-size: 1.5rem;
  font-weight: 400 !important;
  letter-spacing: 0.01em;
  margin-top: 0.8rem;
  width: 70%;
  margin-left: 5px;
  line-height: 28.5px;
}

.registration-step {
  margin-top: 4rem;
  display: flex;
}

.registration-step-texts {
  margin-left: 4.3rem;
}

.registration-count {
  font-size: 1.8rem;
  line-height: 160%;
  letter-spacing: 0.1em;
  color: #7cd1fa;
  font-weight: 500;
}

.registration-text {
  font-size: 1.6rem;
  margin-top: 1.2rem;
  width: 80%;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 15.4rem auto 0 auto;
}
.register-btn {
  margin: 4rem auto 0 auto !important;
}
.btn-primary {
  color: #ffffff;
  background: #0096d7;
}

.btn-light {
  color: black !important;
  background: #f8f8f8;
}

.btn-transparent {
  color: #ffffff;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.btn-text {
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: #ffffff;
}
.check-status-btn {
  width: 109%;
}
/* check your registration page */

.form-group {
  margin-top: 6rem;
}

.input-group {
  margin-top: 3.2rem;
}

.input-group input {
  width: 65%;
}

.input-group label,
.input-group a,
.input-group label * {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  .required {
    font-size: 12px;
  }
}

.horizontal-line {
  width: 95%;
  height: 0px;
  border-bottom: 2px solid #ffffff;
  opacity: 0.3;
}

.line-content {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: 10px;
}
.register-text {
  margin-left: 9px;
  margin-top: 5px;
}
.line-content .horizontal-line {
  border: 0.8px solid #ffffff;
}

.line-content p {
  text-align: center;
  width: 10%;
  font-size: 1.8rem;
  opacity: 0.5;
}

.info-content {
  position: relative;
  width: 30%;
}
.info-content img {
  cursor: pointer;
  margin-left: 1.5rem;
}
.info-box {
  width: 116%;
  margin: 3px 9px 0;
  position: absolute;
  padding: 1.8rem;
  background-color: #ffffff;
  box-shadow: 0px 15px 49px rgba(0, 0, 0, 0.25);
  left: 0.4rem;
  top: 4.4rem;
  border-radius: 9px;
}

.info-text {
  color: #1c4167;
  line-height: 160%;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-size: 1.6rem;
}

.info-vector {
  position: absolute;
  padding: 1rem;
  transform: rotate(45deg);
  top: -0.6rem;
  left: 0.7rem;
  background-color: #ffffff;
}

.forgot-popup {
  font-size: 13px;
  font-weight: 500;
  margin-top: 0.8rem;
  display: inline-block;
  text-decoration: underline;
  letter-spacing: 0.02em;
}

@media only screen and (max-width: 1024px) {
  @import "../small";
}

@media only screen and (max-width: 576px) {
  @import "../mobile";
  .top-texts .top-label {
    font-size: 3.3rem;
  }
  .bottom-texts .bottom-label {
    font-size: 3rem;
  }
  .top-texts .sub-top-label {
    margin-top: 1rem !important;
    margin-bottom: 1.4rem !important;
    font-size: 1.5rem !important;
  }
  .bottom-texts .sub-bottom-label {
    font-size: 1.7rem !important;
    width: 100% !important;
  }
  .right-content {
    height: auto !important;
  }
  .MuiStepConnector-root {
    display: none !important;
  }
  .MuiStepper-root {
    justify-content: space-evenly !important;
  }
  .MuiStep-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
