.css-79ws1d-MuiModal-root {
  overflow: scroll;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-header {
  text-align: right;
}

.modal-header a {
  // border-radius: 50%;
  // padding: 1rem;
  // width: 30px;
  // height: 30px;
  // border: 2px solid blue;
  // color: blue;
  // position: relative;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid #1c4167;
  background-color: #ffffff;
  cursor: pointer;
  display: inline-block;
}

// .modal-header a:hover {
//   border: 2px solid blue;
//   background-color: yellow;
//   color: #ffffff;
// }

// .modal-header a::before {
//   content: " ";
//   position: absolute;
//   display: block;
//   background-color: blue;
//   width: 2px;
//   left: 12px;
//   top: 5px;
//   bottom: 5px;
//   transform: rotate(45deg);
// }

// .modal-header a::after {
//   content: " ";
//   position: absolute;
//   display: block;
//   background-color: blue;
//   height: 2px;
//   top: 12px;
//   left: 5px;
//   right: 5px;
//   transform: rotate(45deg);
// }

.modal-body {
  padding: 4rem;
}

//notification modal
.alert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.information-content {
  font-size: 1.8rem;
}

//Forgot Reference Number modal
.css-yjssu3 {
  min-height: 90%;
  position: relative;
  transform: translate(-50%, auto) !important;
  padding: 0 !important;
  display: flex;
  align-items: center;

  .modal-header {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .top-text-sub-label {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    width: 70%;
    line-height: 28.8px;
  }
}

@media only screen and (max-width: 576px) {
  .css-1jl6ui8 {
    width: 90%;
    overflow: scroll;
  }

  .modal-container {
    margin-top: 20rem;
  }

  .modal-body {
    padding: 1rem;
  }
  .css-79ws1d-MuiModal-root .modal-container {
    margin-top: 0;
  }
}
