// .top-texts{
//     margin-top: 2rem;
// }

// .container{
//     margin: 2rem 6.7rem;
// }

// .buttons{
//     margin: 2.4rem auto 0 auto;
// }

.homepage-content{
    overflow: scroll;
}